import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

function getHeaders ({ app }) {
  const headers = {}

  return headers
}

export default (ctx, inject) => {
  const options = {"broadcaster":"pusher","authModule":false,"connectOnLogin":true,"disconnectOnLogout":true,"key":"44a8eeb4fd7006c0b644","cluster":undefined,"host":"api.meleton.ru","enabledTransports":["ws","wss","websocket","polling","flashsocket"],"wsHost":"api.meleton.ru","wssHost":"api.meleton.ru","protocol":"api.meleton.ru","port":"443","authEndpoint":"https:\u002F\u002Fapi.meleton.ru\u002Fbroadcasting\u002Fauth","disableStats":true}
  options.auth = options.auth || {}
  options.auth.headers = {
    ...options.auth.headers,
    ...getHeaders(ctx)
  }

  const echo = new Echo(options)

  ctx.$echo = echo
  inject('echo', echo)
}
