

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"numberFormats":{"ru":{"currency":{"style":"currency","currency":"RUB","currencyDisplay":"symbol","maximumFractionDigits":"0","minimumFractionDigits":"0"}},"en":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol","maximumFractionDigits":"0","minimumFractionDigits":"0"}},"de":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol","maximumFractionDigits":"0","minimumFractionDigits":"0"}},"es":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol","maximumFractionDigits":"0","minimumFractionDigits":"0"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en-US.js","name":"English","shortName":"EN","domain":"meleton.com"},{"code":"pt","iso":"pt-PT","file":"pt-PT.js","name":"Português","shortName":"PT","domain":"pt.meleton.com"},{"code":"ru","iso":"ru-RU","file":"ru-RU.js","name":"Русский","shortName":"RU","domain":"meleton.ru"},{"code":"es","iso":"es-ES","file":"es-ES.js","name":"Español","shortName":"ES","domain":"es.meleton.com"},{"code":"de","iso":"de-DE","file":"de-DE.js","name":"German","shortName":"DE","domain":"de.meleton.com"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/builds/m10071/melton-frontend/features/Lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true},
  differentDomains: true,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US.js","name":"English","shortName":"EN","domain":"meleton.com"},{"code":"pt","iso":"pt-PT","file":"pt-PT.js","name":"Português","shortName":"PT","domain":"pt.meleton.com"},{"code":"ru","iso":"ru-RU","file":"ru-RU.js","name":"Русский","shortName":"RU","domain":"meleton.ru"},{"code":"es","iso":"es-ES","file":"es-ES.js","name":"Español","shortName":"ES","domain":"es.meleton.com"},{"code":"de","iso":"de-DE","file":"de-DE.js","name":"German","shortName":"DE","domain":"de.meleton.com"}],
  localeCodes: ["en","pt","ru","es","de"],
}

export const localeMessages = {
  'en-US.js': () => import('../../features/Lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'pt-PT.js': () => import('../../features/Lang/pt-PT.js' /* webpackChunkName: "lang-pt-PT.js" */),
  'ru-RU.js': () => import('../../features/Lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
  'es-ES.js': () => import('../../features/Lang/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
  'de-DE.js': () => import('../../features/Lang/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
}
